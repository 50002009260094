import request from '@/utils/request'
import { obj2url } from '@/utils/tools'

// 密码登录
export const login = (data) => {
  return request({
    url: '/auth/login/using-password',
    method: 'POST',
    data
  })
}
// 手机验证码登录
export const loginByPhone = (data) => {
  return request({
    url: '/auth/login/using-phone',
    method: 'POST',
    data
  })
}
export const registerByPhone = (data) => {
  return request({
    url: '/auth/signup/using-phone',
    method: 'POST',
    data
  })
}
// 获取验证码
export const getCaptcha = (data) => {
  return request({
    url: '/auth/verify-codes/captcha',
    method: 'POST',
    data
  })
}

// 手机号是否存在
export const isPhoneExist = (data) => {
  return request({
    url: '/auth/signup/phone/exist',
    method: 'POST',
    data
  })
}

// 用户名是否存在
export const isNameExist = (data) => {
  return request({
    url: '/auth/signup/name/exist',
    method: 'POST',
    data
  })
}
// 刷新token
export const getRefreshToken = () => {
  return request({
    url: '/auth/login/refresh-token',
    method: 'POST'
  })
}

// 获取手机验证码
export const getPhoneCode = (data) => {
  return request({
    url: '/auth/verify-codes/phone',
    method: 'POST',
    data
  })
}
// 获取当前user
export const getCurrentUser = () => {
  return request({
    url: '/users/userinfo',
    method: 'GET'
  })
}
// 更新头像
export const updateAvatar = (data) => {
  return request({
    url: '/users/avatar',
    method: 'PUT',
    data
  })
}
// 上传头像
export const uploadAvatar = (data, headers) => {
  return request({
    url: '/users/upload-avatar',
    method: 'POST',
    data,
    headers
  })
}
// 更新用户信息
export const updateUser = (data) => {
  return request({
    url: '/users',
    method: 'PUT',
    data
  })
}
// 修改手机号
export const updatePhone = (data) => {
  return request({
    url: '/users/phone',
    method: 'PUT',
    data
  })
}
// 修改密码
export const updatePassword = (data) => {
  return request({
    url: '/users/password',
    method: 'PUT',
    data
  })
}
// 修改微信
export const updateWeChat = (data) => {
  return request({
    url: '/users/wechat',
    method: 'PUT',
    data
  })
}
// 获取用户微信
export const getWeChat = (user_id) => {
  return request({
    url: '/users/wechat?user_id=' + user_id,
    method: 'GET'
  })
}
// 获取用户项目
export const getUserProject = (data) => {
  return request({
    url: '/users/project/' + data.user_id,
    method: 'GET'
  })
}

// 获取用户数
export const userCount = () => {
  return request({
    url: '/auth/stat/count',
    method: 'GET'
  })
}

// 信息公开
export const updatePublic = (data) => {
  return request({
    url: '/users/show',
    method: 'PUT',
    data
  })
}
// 获取合作伙伴列表
export const getPartnerList = (data) => {
  const url = obj2url('/users/partner', data)
  return request({
    url: url,
    method: 'GET'
  })
}
