export default [
  {
    title: '首页',
    href: '/'
  },
  {
    title: '免费课程',
    href: '/free'
  },
  {
    title: '课程会员',
    href: '/video-class'
  },
  {
    title: '加群申请',
    href: '/group'
  },
  {
    title: '自营团队',
    href: '/join_us'
  }
]
