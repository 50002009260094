import layout from '@/layout'
import NotFooterLayout from '@/layout/NotFooter.vue'
export const publicRoutes = [
  {
    path: '/',
    component: layout,
    children: [
      {
        path: '',
        component: () => import('@/views/home/index.vue'),
        name: 'home',
        meta: {
          title: '首页'
        }
      },
      {
        path: '/join_us',
        component: () => import('@/views/join/index.vue'),
        name: 'join_us',
        meta: {
          title: '加入我们'
        }
      },
      {
        path: '/order/:id',
        component: () => import('@/views/order/index.vue'),
        name: 'order',
        meta: {
          title: '支付订单'
        }
      },
      {
        path: '/order/renew/:id',
        component: () => import('@/views/order/renew.vue'),
        name: 'order-renew',
        meta: {
          title: '会员续费'
        }
      },
      {
        path: '/group',
        component: () => import('@/views/home/grouplist.vue'),
        name: 'group',
        meta: {
          title: '微信群'
        }
      },
      {
        path: '/user/tips',
        component: () => import('@/views/user/layout/tips'),
        name: 'user-tips',
        meta: {
          title: '提示'
        }
      },
      {
        path: '/video-class',
        component: () => import('@/views/video-course/index.vue'),
        name: 'video-class',
        meta: {
          title: '视频路线'
        }
      },
      {
        path: '/free',
        component: () => import('@/views/video-course/free.vue'),
        name: 'video-class-free',
        meta: {
          title: '免费课程'
        }
      },
      {
        path: '/video-class/introduce/:course_id',
        component: () => import('@/views/video-course/introduce.vue'),
        name: 'video-class-introduce',
        meta: {
          title: '视频课程介绍'
        }
      }
    ]
  },

  {
    path: '/video-class',
    component: NotFooterLayout,
    children: [
      {
        path: '/video-class/book/detail/:id',
        component: () => import('@/views/video-course/book/detail.vue'),
        name: 'video-class-book-detail',
        meta: {
          title: '视频配套文档详情'
        }
      }
    ]
  },
  {
    path: '/video-class/video/detail/:course_id',
    component: () => import('@/views/video-course/detail.vue'),
    meta: {
      title: '视频播放'
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
    component: layout,
    children: [
      {
        path: '',
        component: () => import('@/views/404'),
        name: '404',
        meta: {
          title: '体系课'
        }
      }
    ]
  }
]
