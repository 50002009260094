import moment from 'moment'
const getters = {
  token: (state) => state.user.token,
  expand: (state) => state.app.expand,
  isOrderSearch: (state) => state.app.isOrderSearch,
  searchValue: (state) => state.app.searchValue,
  userInfo: (state) => state.user.userInfo,
  activeNav: (state) => state.app.activeNav,
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  selectedKey: (state) => state.user.selectedKey,

  // 是否是基础VIP会员
  isVideoVIP: (state) => {
    const vip_list = state.user.userInfo.vip_list
    // 找到返回true，找不到返回false
    return vip_list.some((item) => item.Vip.name === '高级课程会员')
  },
  isVideoVIPExpired: (state) => {
    const currentTime = Date.now()
    // 查找高级课程会员
    const videoVip = state.user.userInfo.vip_list.find(
      (item) => item.Vip.name === '高级课程会员'
    )

    // 如果没有找到高级课程会员，返回 false
    if (!videoVip) {
      return false
    }

    // 如果找到了，检查是否过期
    const expiration = moment(videoVip.expiration).valueOf()
    return currentTime > expiration
  }
}
export default getters
